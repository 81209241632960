<template>
    <div class="wrap">
        <CancelModal v-if="cancelStatus" />
        <CancelCheckModal
            v-if="cancelCheckStatus"
            :curationinfo="curationInfo"
            :isRegistCard="isRegistCard"
            :spaceInfo="spaceInfo"
            @closeCancel="cancelCheckStatus = false"
            :isMaterial="'Y'"
            :materialValue="selectMaterial.length > 0 ? selectMaterial.sort((a, b) => a - b).join(',') : null"
            @showCancel="cancelStatus = true"
            @deletspace="onDeleteSpace"
        />
        <popup-modal v-if="isPopUpModal" :text="popupModalText" @close-modal="onCloseModal" />
        <!-- ################### PC ################### -->
        <div class="space_pc">
            <curationinfo-modal
                :curationInfo="curationInfo"
                v-if="showCurationInfo"
                @closeCurationInfo="showCurationInfo = false"
            />
            <section class="main registration">
                <div class="main_box my_space3">
                    <div class="main_box_title">
                        <h1 class="spaceinsert_title">스페이스 등록</h1>
                        <div @click="linkMySpace()">
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </div>
                    <div class="cs_main">
                        <div class="myspace_content qration">
                            <div class="form_text">
                                <h3 class="spaceinsert_subtitle">매장 인테리어의 주요 소재를 선택해주세요. (최대 3개)</h3>
                            </div>
                            <div class="pagnation">
                                <span></span>
                                <span style="width: 36%"></span>
                            </div>
                        </div>
                        <ul class="spaceinsert_marterial_container three_type fade-up">
                            <li class="spaceinsert_marterial_item" v-for="item in pcGetMaterial1" :key="`material${item.no}`">
                                <label
                                    :for="`material${item.no}`"
                                    class="spaceinsert_marterial_item_label cursor"
                                    :class="{ active: selectMaterial.includes(item.no) }"
                                    @click="clickMaterial(item.no)"
                                >
                                    {{ item.materialNm }}
                                </label>
                                <input type="checkbox" :id="`material${item.no}`" :value="item.no" hidden />
                            </li>
                        </ul>
                        <ul class="spaceinsert_marterial_container second three_type fade-up">
                            <li class="spaceinsert_marterial_item second" v-for="item in pcGetMaterial2" :key="`material${item.no}`">
                                <label
                                    :for="`material${item.no}`"
                                    class="spaceinsert_marterial_item_label cursor"
                                    :class="{ active: selectMaterial.includes(item.no) }"
                                    @click="clickMaterial(item.no)"
                                >
                                    {{ item.materialNm }}
                                </label>
                                <input type="checkbox" :id="`material${item.no}`" :value="item.no" hidden />
                            </li>
                        </ul>
                    </div>
                    <div class="form_bottom space_1">
                        <div class="form_bottom_box">
                            <div>
                                <svg
                                    width="36"
                                    height="36"
                                    viewBox="0 0 36 36"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    @click="showCurationInfo = true"
                                >
                                    <path
                                        d="M18.636 16.828L13.686 21.778L12.272 20.364L18.636 14L25 20.364L23.586 21.778L18.636 16.828Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                            <div class="space_bottom_list">
                                <figure v-for="(item, index) in bottomList" :key="`bottom${index}`" :class="item.cssclass">
                                    <img class="curation_info_box_img" :src="item.url" />
                                </figure>
                            </div>
                            <div>
                                <button id="prev_btn" @click="clickPre()">이전</button>
                                <button class="space_next_btn" id="next_btn" @click="updateCuration" :disabled="btnDisabled">
                                    다음
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- ################### 모바일 ################### -->
        <div class="space_mo h_100">
            <mobilecurationinfo-modal
                :curationInfo="curationInfo"
                v-if="showCurationInfoM"
                @closeCurationInfo="showCurationInfoM = false"
            />
            <div class="mo_space_header">
                <div class="mo_header_box">
                    <a>
                        <img src="/media/images/icon_back_24.svg" @click="clickPre()" />
                    </a>
                    <h2>스페이스 등록</h2>
                    <div class="btn_close" @click="linkMySpace()">
                        <img src="/media/images/icon_close_24.svg" />
                    </div>
                </div>
                <div class="pagnation">
                    <span></span>
                    <span style="width: 36%"></span>
                </div>
            </div>
            <section class="main myspace">
                <div class="main_box">
                    <div class="myspace_content_form">
                        <div class="form_text">
                            <h3 class="spaceinsert_subtitle">매장 인테리어의 주요 소재를 선택해주세요.(최대 3개)</h3>
                        </div>
                        <ul class="spaceinsert_marterial_container three_type fade-up">
                            <li
                                class="spaceinsert_marterial_item"
                                v-for="(item, index) in moGetMaterial1"
                                :class="index === moGetMaterial1.length - 1 ? 'last' : ''"
                                :key="`material${item.no}`"
                            >
                                <label
                                    :for="`material${item.no}`"
                                    class="spaceinsert_marterial_item_label cursor"
                                    :class="{ active: selectMaterial.includes(item.no) }"
                                    @click="clickMaterial(item.no)"
                                >
                                    {{ item.materialNm }}
                                </label>
                                <input type="checkbox" :id="`material${item.no}`" :value="item.no" hidden />
                            </li>
                        </ul>
                    </div>
                    <div class="types_bottom">
                        <svg
                            @click="showInfo('target')"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.3025 2.92566L2.3525 7.87566L0.938504 6.46166L7.3025 0.0976563L13.6665 6.46166L12.2525 7.87566L7.3025 2.92566Z"
                                fill="white"
                            />
                        </svg>
                        <div class="mo_space_bottom_list">
                            <figure v-for="(item, index) in bottomList" :key="`bottom${index}`" :class="item.cssclass">
                                <img class="curation_info_box_img" :src="item.url" />
                            </figure>
                        </div>
                    </div>
                    <div class="form_bottom">
                        <button class="space_next_btn" id="next_btn" @click="updateCuration" :disabled="btnDisabled">다음</button>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import CancelModal from '@/components/modal/Space/CancelModal.vue';
import PopUpModal from '@/components/modal/common/PopUpModal.vue';
import CancelCheckModal from '@/components/modal/Space/CancelCheckModal.vue';
import CurationInfo from '@/components/modal/Space/CurationInfo.vue';
import CurationInfoM from '@/components/modal/Space/CurationInfoM.vue';
import {
  getSpaceCurationInfo,
  getMaterialList,
  setSpaceMaterial,
  checkSubscribe,
  updateConfirmCuration
} from '@/service/api/profileApi';
export default {
  name: 'SpaceMaterial',
  components: {
    CancelModal,
    CancelCheckModal,
    'mobilecurationinfo-modal': CurationInfoM,
    'popup-modal': PopUpModal,
    'curationinfo-modal': CurationInfo
  },
  data () {
    return {
      cancelStatus: false,
      cancelCheckStatus: false,
      curationInfo: {},
      isRegistCard: true,
      spaceInfo: null,
      isPopUpModal: false,
      popupModalText: '',
      selectMaterial: [],
      showCurationInfo: false,
      spaceId: localStorage.getItem('spaceId'),
      bottomList: [],
      showCurationInfoM: false,
      color: -1,
      curationId: ''
    };
  },

  created () {
    getMaterialList()
      .then(res => {
        const { resultCd, result } = res.data;
        if (resultCd === '0000') {
          this.$store.commit('curation/setCurationMaterial', result);
        } else {
          this.$store.commit('curation/setCurationMaterial', []);
        }
      })
      .catch(() => {
        this.$store.commit('curation/setCurationMaterial', []);
      });
    const aToken = this.$cookies.get('aToken');
    const headers = { 'X-AUTH-TOKEN': aToken };
    getSpaceCurationInfo(this.spaceId, headers)
      .then(res => {
        const { resultCd, result } = res.data;
        if (resultCd === '0000') {
          this.curationInfo = result;
          this.curationId = result.curationId;
          if (Object.keys(result.color).length > 0) {
            const findColor = this.$store.getters['curation/getColorItem'](result.color);

            this.color = findColor.no;
            this.bottomList.push({
              cssclass: 'curation_info_box color',
              url: `${findColor.img}`
            });
          }
          if (result.material) {
            result.material.forEach(ele => {
              const findMaterial = this.$store.getters['curation/getMaterialValue'](ele);
              if (Object.keys(findMaterial).length > 0) {
                this.selectMaterial.push(findMaterial.no);
              } else {
                this.selectMaterial = [];
              }
            });
          } else {
            this.selectMaterial = [];
          }
        } else {
          this.selectMaterial = [];
        }
      })
      .catch(() => {
        this.selectMaterial = [];
      });
  },

  mounted () {
    document.querySelector('html').scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  },

  methods: {
    clickMaterial (val) {
      const findIdx = this.selectMaterial.findIndex(item => item === val);

      if (findIdx > -1) {
        this.selectMaterial.splice(findIdx, 1);
      } else {
        if (this.selectMaterial.length < 3) {
          this.selectMaterial.push(val);
        }
      }
    },

    onDeleteSpace (msg) {
      this.isPopUpModal = true;
      this.popupModalText = msg;
    },
    onCloseModal () {
      this.isPopUpModal = false;
      this.$router.push({ name: 'MySpace' });
    },
    linkMySpace () {
      const spaceId = localStorage.getItem('spaceId');
      checkSubscribe(spaceId).then(res => {
        const { resultCd, result } = res.data;
        if (resultCd === '0000') {
          if (result === 'S') {
            this.cancelCheckStatus = true;
          } else {
            this.$router.push({ name: 'Player' }).catch(() => {});
          }
        }
      });
    },
    clickPre () {
      this.$router.push({ name: 'SpaceInsertStoreColor' });
    },
    async updateCuration () {
      try {
        const { data: materialData } = await setSpaceMaterial(
          this.spaceId,
          this.selectMaterial.sort((a, b) => a - b).join(',')
        );
        const { resultCd: materialResultCd } = materialData;
        if (materialResultCd === '0000') {
          const { data: confirmCurationData } = await updateConfirmCuration(this.spaceId, this.curationInfo.curationId);
          const { resultCd: confirmCurationResultCd } = confirmCurationData;
          if (confirmCurationResultCd === '0000') {
            this.$router.push({ name: 'SpaceInsertStoreTarget' }).catch(() => {});
          }
        }
      } catch (error) {
        //
      }
    },
    showInfo (index) {
      this.curationInfo.infoStatus = index;
      this.curationInfo.infoCategory = 'insert';
      this.showCurationInfoM = true;
    }
  },

  computed: {
    pcGetMaterial1 () {
      return this.$store.getters['curation/getCurationMaterial'].slice(0, 8);
    },
    pcGetMaterial2 () {
      return this.$store.getters['curation/getCurationMaterial'].slice(8, 10);
    },
    moGetMaterial1 () {
      return this.$store.getters['curation/getCurationMaterial'];
    },
    moGetMaterial2 () {
      return this.$store.getters['curation/getCurationMaterial'].slice(4, 8);
    },
    moGetMaterial3 () {
      return this.$store.getters['curation/getCurationMaterial'].slice(8, 10);
    },
    btnDisabled () {
      if (this.selectMaterial) {
        if (this.selectMaterial.length >= 1 && this.selectMaterial.length <= 3) {
          return false;
        }
      }

      return true;
    }
  }
};
</script>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
<style scoped src="@/assets/css/spacemanage/spaceinsert.css"></style>
